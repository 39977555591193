@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-moz-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-o-keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@keyframes BackgroundAnimation {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@keyframes progress {
  from {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform-origin: left;
    transform: scaleX(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.blog-fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

.blog-fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

/* Home page transitions */
.home-fade-in {
  animation: fadeIn 0.5s ease-in forwards;
}

.home-fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

/* Page transition classes */
.fade-out-transition {
  animation: fadeOut 0.4s ease-out forwards;
}

.fade-in-transition {
  animation: fadeIn 0.4s ease-in forwards;
}
